<template>
  <div>
    <div id="page" v-if="registrations.length > 0">
        <badge  v-for="registration in registrations" :key="registration.id" :registration="registration"></badge>
    </div>

    <div id="guides">
      <hr style="top: 0.5in;" />
      <hr style="top: 5.5in;" />
      <hr style="top: 10.5in;" />
      <hr class="vr" style="left: 0.75in;" />
      <hr class="vr" style="left: 4.25in;" />
      <hr class="vr" style="left: 7.75in;" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import Badge from '../components/Badge';

export default {
  name: 'Registrations',
  components: { Badge },
  props: {
    globals: Object,
  },
  data() {
    return {
      registrations: [],
    }
  },
  methods: {
    async loadHandler() {
      const request = {
        url: this.globals.endpoint + '/v3/batch/',
        method: 'post',
        data: {
          calls: [],
        }
      }

      let ids;
      if( Array.isArray(this.$route.query.ids) ) {
        ids = this.$route.query.ids;
      } else {
        ids = [this.$route.query.ids];
      }

      for( const id of ids ) {
        request.data.calls.push([
          'get',
          'registrations',
          id,
          {
            $eager: '[group,profile,tags]'
          }
        ])
      }

      const result = await axios(request).catch(error => {
        console.log(error);
      })
      
      const registrations = [];
      for( const record of result.data ) {
        if( record.status === 'fulfilled') registrations.push(record.value);
      }

      if(registrations.length === ids.length) {
        this.registrations = registrations;
      }

    }
  },
  created() {
    this.loadHandler();
  }
}
</script>

<style>

#page {
  position: absolute;
  top: 0.5in;
  left: 0.75in;
}

.badge {
  position: inherit;
}

.badge:nth-of-type(2), .badge:nth-of-type(4) {
  left: 3.5in;
}

.badge:nth-of-type(3), .badge:nth-of-type(4) {
  top: 5in;
}

.badge .snr-bg {
  opacity: 0.25;
}

/* START: GUIDES */
@media print
{    
    #guides
    {
        /* display: none !important; */
    }
}

#guides {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
hr {
  position: absolute;
  width: 8.5in;
  height: 0;
  border-top: 4px dashed orange;
  border-bottom: none;
  margin: -2px 0 0 0;
  padding: 0;
}

hr.vr {
  width: 0;
  height: 11in;
  border: none;
  border-left: 4px dashed orange;
  margin: 0 0 0 -2px;
}
/* END: GUIDES */
</style>